.bg-cream {
  background-color: #fffce9;
}

.get-started-link {
  box-shadow: 0px 8px 30px rgba(51, 152, 255, 0.1);
}

footer .footer-top {
  background: linear-gradient(180deg, #5f33ff 0%, rgba(95, 103, 255, 1) 100%);
  /* filter: drop-shadow(0px 20px 100px #5c6bfe); */
}

footer .footer-bottom {
  background: linear-gradient(180deg, #6067fe 19.89%, #3398ff 100%);
}

.text-outlined-white {
  font-weight: bolder;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}

.text-outlined-purple {
  font-weight: bolder;
  color: white;
  text-shadow: -1px -1px 0 var(--color-primary), 1px -1px 0 var(--color-primary),
    -1px 1px 0 var(--color-primary), 1px 1px 0 var(--color-primary);
}

.underline {
  height: 1px;
  background-color: white;
  width: 100%;
}

input,
.price-box {
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08);
}

button {
  box-shadow: 0px 8px 30px rgba(51, 152, 255, 0.2);
}

.testimonial-section {
  background: linear-gradient(180deg, #3398ff 19.89%, #6067fe 100%);
}
.testimonial-section::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../images/svg/testimonialbg.svg");
  background-size: 90%;
  background-position: center 5rem;
  background-repeat: no-repeat;
}

.slideupDown {
  animation: slide 1s ease-in-out both infinite;
}

@keyframes slide {
  from {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  to {
    transform: translateY(0px);
  }
}

.modal-bg {
  background-image: url("../images/svg/gradient.svg");
  background-repeat: no-repeat;
  background-position: center top;
}

.hover-scale {
  transition: all ease-in-out 200ms;
  transform: scale(1);
}

.hover-scale:hover {
  transform: scale(1.05);
}

.contact-link {
  box-shadow: 0px 8.70741px 15.1257px #e5e6fe;
  border-radius: 11.3443px;
  background: #ffffff;
  border: 1px solid #bacaff;
  box-sizing: border-box;
}

.faq-section {
  background-color: #f0f7ff;
}

.faq-toggle::before {
  content: "-";
}

.faq-toggle.show::before {
  content: "+";
}

.pricing-card {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.our-plan-card {
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.our-plan-button {
  box-shadow: 0px 8.70741px 15.1257px rgba(0, 0, 0, 0.05);
}

.left-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: calc(64px + 12vh);
}

.calculator-box {
  width: 35rem;
  height: calc(100vh / 1.3);
  box-shadow: rgb(0 0 0 / 6%) 0px 1px 6px 0px, rgb(0 0 0 / 16%) 0px 2px 32px 0px;
}

.calculator-box .item-list {
  height: calc(100vh / 1.5);
  overflow: auto;
  padding-bottom: 6rem;
}

@media screen and (max-width: 768px) {
  .testimonial-section::after {
    background-size: 100%;
  }
  .left-sticky {
    top: 0;
    position: relative;
  }

  .calculator-box {
    width: 95%;
    height: calc(100vh / 1.3);
  }
}

/* HOMEPAGE PRICING RADIO BUTTON START */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #c4c4c4;
}

[type="radio"]:checked + label {
  color: #5f33ff;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #5f33ff;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:not(:checked) + label:before {
  border: 1px solid #c4c4c4;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #5f33ff;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* HOMEPAGE PRICING RADIO BUTTON END */

/* PRICING PAGE RADIO BUTTON START */
input[type="radio"].pricing-radio {
  display: none;
}

input[type="radio"].pricing-radio:checked + .box {
  background-color: #07b037;
}

input[type="radio"].pricing-radio:checked + .box.blue {
  background-color: #3398ff;
}

input[type="radio"].pricing-radio:checked + .box span {
  color: white;
}

input[type="radio"].pricing-radio:checked + .box span::before {
  transform: translateY(0px);
  opacity: 1;
}

.box {
  width: 100%;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  background: #000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.box:active {
  transform: translateY(10px);
}
.box span {
  transition: all 300ms ease;
  user-select: none;
  color: #fff;
}

.box span::before {
  display: block;
  opacity: 0;
  transition: all 300ms ease-in-out;
  color: white;
}
/* PRICING PAGE RADIO BUTTON END */

/* PRELOADER */
.rotate-preloader-icon {
  animation: rotateAndScale 2s ease-in-out both infinite;
}

@keyframes rotateAndScale {
  from {
    transform: rotate(0deg) scale(0.9);
    transform-origin: center;
  }

  50% {
    transform: rotate(180deg) scale(1.15);
    transform-origin: center;
  }

  to {
    transform: rotate(360deg) scale(0.9);
    transform-origin: center;
  }
}

.subsribe-left-container {
  background: url("../images/webp/cleeen.webp") no-repeat;
  background-size: contain;
  background-position: bottom center;
}

.subsribe-left-container .bubble img {
  animation: floatAround ease-in-out 4s both infinite;
}

.subsribe-left-container .bubble img:first-child {
  animation-delay: 1s;
}

.subsribe-left-container .bubble img:last-child {
  animation-delay: 2s;
}

@keyframes floatAround {
  from {
    transform: translate(0);
  }

  20% {
    transform: translate(100px, 80px);
  }

  50% {
    transform: translate(80px, 0px);
  }

  to {
    transform: translate(0);
  }
}

.subscription-tag {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.subscribe-banner {
  background: url("../images/webp/bubble.webp") 3rem 1rem,
    url("../images/webp/bubble.webp") -0.5rem;
  background-repeat: no-repeat;
  background-size: 10%;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}
.valentine-bg {
background: url("../images/webp/valentine-modal.webp") #fff no-repeat;
background-size: cover;
background-position: center;
height: 80vh !important;
}

.valentine-bg::after {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.3);
}