/* TempOwn Default Settings */
:root {
  /*Default  Color */
  --color-primary: #5f33ff;
  --color-iron-blue: #5077ff;
  --color-washed-blue: #4188ff;
  --color-soap-blue: #3398ff;
  --color-water-blue: #24a9ff;
  --color-bubble-blue: #15b9ff;

  --color-light-purple: #bacaff;
  --color-light-purple-2: #e5e6fe;

  --color-green: #07b037;

  --color-gold: #b97a00;
  --color-orange: #f29c1f;

  --color-gray: #7f8284;
  --color-gray2: #E5E5E5;
}

@tailwind base;
@tailwind components;

@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Tis Sans Pro";
    font-weight: 300;
    src: url(../fonts/TisaSansPro-Light.ttf);
  }

  @font-face {
    font-family: "Tis Sans Pro";
    font-weight: 400;
    src: url(../fonts/TisaSansPro-Regular.ttf);
  }

  @font-face {
    font-family: "Tis Sans Pro";
    font-weight: 500;
    src: url(../fonts/TisaSansPro-Medium.ttf);
  }
  @font-face {
    font-family: "Tis Sans Pro";
    font-weight: 700;
    src: url(../fonts/TisaSansPro-Bold.ttf);
  }
  @font-face {
    font-family: "Tis Sans Pro";
    font-weight: 800;
    src: url(../fonts/TisaSansPro-ExtraBold.ttf);
  }
}

@import "./main.css";
